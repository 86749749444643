<template>
  <div class="text-center">
    <v-bottom-sheet v-model="add_new_popup" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot name="add">
            <h4 class="show-all">Add Event</h4>
          </slot>
        </div>
      </template>
      <v-sheet v-if="add_new_popup" class="pa-4" ref="form">
        <div class="flex-between pb-8">
          <h3>Add a Event</h3>
        </div>
        <v-text-field
          ref="title"
          v-model="title"
          :rules="[rules.required]"
          label="Title"
          dense
          required
          outlined
          class="mb-3"
        ></v-text-field>
        <v-text-field
          ref="place"
          v-model="place"
          :rules="[rules.required]"
          required
          label="Place"
          dense
          outlined
          class="mb-3"
        ></v-text-field>
        <v-dialog
          ref="event_modal"
          v-model="event_modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="date_of_event"
              v-model="date_of_event"
              label="Date of event"
              readonly
              v-bind="attrs"
              :rules="[rules.required]"
              v-on="on"
              dense
              outlined
              required
              class="mb-3"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_of_event"
            :active-picker.sync="eventPicker"
            :min="new Date(Date.now()).toISOString().substr(0, 10)"
            @change="event_modal = false"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="event_modal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              :disabled="!date_of_event"
              color="primary"
              @click="$refs.event_modal.save(date_of_event)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-select
          :items="items"
          v-model="group"
          label="Select Group"
          dense
          outlined
        ></v-select>

        <div class="flex">
          <div
            @click="add_new_popup = false"
            class="kvk-button is-cancel width-50 mr-2"
          >
            Cancel
          </div>
          <div @click="addEvent" class="kvk-button width-50 ml-2">
            Add Event
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {
  doc,
  eventsCollection,
  serverTimestamp,
  addDoc,
  updateDoc,
} from "@/firebaseconfig.js";
export default {
  data() {
    return {
      add_new_popup: false,
      title: null,
      place: null,
      date_of_event: null,
      error: null,
      eventPicker: null,
      date: null,
      group: null,
      event_modal: false,
      formHasErrors: false,
      // items: ["Foo", "Bar", "Fizz", "Buzz"],
      rules: {
        required: (value) => !!value || "Required.",
        // counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    // console.log(new Date().getFullYear());
  },
  computed: {
    form() {
      return {
        title: this.title,
        place: this.place,
        date_of_event: this.date_of_event,
        group: this.group,
      };
    },
    items() {
      let currentYear = new Date().getFullYear();
      let value = [currentYear];
      for (let i = 0; i < 4; i++) {
        currentYear++;
        value.push(currentYear);
      }
      return value;
    },
  },
  watch: {
    event_modal(val) {
      val && setTimeout(() => (this.eventPicker = "YEAR"));
    },
  },
  methods: {
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async addEvent() {
      if (this.title !== null || this.name !== "") {
        this.is_loading = true;

        let start_time = new Date(this.date_of_event);
        start_time.setHours(0, 0, 0);
        let end_time = new Date(this.date_of_event);
        end_time.setHours(23, 59, 59);

        const docRef = await addDoc(eventsCollection, {
          added_time: serverTimestamp(),
          title: this.title,
          name: this.title,
          place: this.place,
          date_of_event: this.date_of_event,
          eventDate: new Date(this.date_of_event),
          // start: new Date(start_time).toMillis(),
          start: start_time,
          end: end_time,
          group: this.group,
        });

        await updateDoc(doc(eventsCollection, docRef.id), {
          id: docRef.id,
        });

        this.add_new_popup = false;
        this.$emit("added");
        this.title = null;
        this.place = null;
        this.date_of_event = null;
        this.formHasErrors = false;
        this.is_loading = false;
      } else {
        console.log("has error");
        this.formHasErrors = false;
        Object.keys(this.form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true;

          this.$refs[f].validate(true);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
