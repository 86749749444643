<template>
  <div>
    <!-- {{ allevents }} -->
    <!-- {{ events }} -->
    <div class="view-select pa-4 mb-4 flex align-center">
      <v-btn
        outlined
        class="mr-4"
        color="grey darken-2"
        @click="
          setToday();
          type = 'month';
        "
      >
        Today
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="type"
      ></v-select>
      <!-- <v-btn-toggle v-model="test" mandatory>
        <v-btn
          @click="
            test = _.indexOf(types, item);
            type = types[_.indexOf(types, item)];
          "
          small
          v-for="item in types"
          :key="item"
        >
          {{ item }}
        </v-btn>
      </v-btn-toggle> -->
    </div>
    <v-sheet tile height="54" class="flex-center">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <icon name="arrow-left" file="feather" class="is-24x24"></icon>
      </v-btn>
      <v-spacer></v-spacer>
      <h4 v-if="$refs.calendar" class="mb-2">
        {{ $refs.calendar.title }}
      </h4>
      <!-- <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="type"
      ></v-select> -->
      <!-- <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
      ></v-select> -->
      <!-- <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
      ></v-select> -->
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <icon name="arrow-right" file="feather" class="is-24x24"></icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        @change="getEvents"
        color="accent"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <!-- <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
            <!-- <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title> -->
            <!-- TODO: Change the time -->
            <v-toolbar-title
              v-html="new Date(selectedEvent.start)"
            ></v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon>
              <v-icon>mdi-heart</v-icon>
            </v-btn> -->
            <!-- <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn> -->
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.details"></span>
            <!-- <div>{{ selectedEvent }}</div> -->
            <div><strong>Title:</strong> {{ selectedEvent.name }}</div>
            <br />
            <div><strong>Place:</strong> {{ selectedEvent.name }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- @click:more="viewDay"
        @click:date="viewDay" -->
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: ["allevents"],
  data: () => ({
    type: "month",
    test: 0,
    focus: "",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  methods: {
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);
        // console.log("asdf", firstTimestamp);
        // events.push({
        //   name: this.names[this.rnd(0, this.names.length - 1)],
        //   start: first,
        //   end: second,
        //   color: this.colors[this.rnd(0, this.colors.length - 1)],
        //   timed: !allDay,
        // });
      }
      this.allevents.map((e) => {
        // const allDay = this.rnd(0, 3) === 0;
        events.push({
          name: e.title,
          start: e.start.toDate(0),
          end: e.end.toDate(),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          // timed: !allDay,
          timed: true,
          place: e.place,
          date_of_event: e.date_of_event,
        });
      });

      this.events = events;
      // this.events = this.allevents;
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>
<style lang="scss" scoped>
.view-select {
  background-color: #fff;
  // padding: 20;
}
</style>
