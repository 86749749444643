var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events pa-4"},[_c('div',{staticClass:"flex mb-4 align-center"},[_c('v-btn-toggle',{attrs:{"color":"accent","group":"","dense":"","mandatory":""},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('v-btn',[_c('icon',{class:['is-24x24', { 'accent-text': _vm.mode == 0 }],attrs:{"name":"list","file":"feather"}})],1),_c('v-btn',[_c('icon',{class:['is-24x24', { 'accent-text': _vm.mode == 1 }],attrs:{"name":"calendar","file":"feather"}})],1)],1),_c('v-spacer'),_c('add-event',{on:{"added":_vm.getData},scopedSlots:_vm._u([{key:"add",fn:function(){return undefined},proxy:true}])})],1),(_vm.events && _vm.events.length && _vm.mode == 1)?_c('div',[_c('calendar-view',{attrs:{"allevents":_vm.events}})],1):(
      _vm.grouped_events && Object.keys(_vm.grouped_events).length && _vm.mode == 0
    )?_c('div',_vm._l((_vm.grouped_events),function(item,asasd){return _c('div',{key:item.uid,staticClass:"mb-5"},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(asasd))]),_vm._l((item),function(event){return _c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: function () {
            _vm.show_delete_icon = true;
            _vm.delete_user = event.id;
          },
          right: function () {
            _vm.show_delete_icon = false;
            _vm.delete_user = null;
          },
        }),expression:"{\n          left: () => {\n            show_delete_icon = true;\n            delete_user = event.id;\n          },\n          right: () => {\n            show_delete_icon = false;\n            delete_user = null;\n          },\n        }"}],key:event.id,staticClass:"events__card"},[_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"body-2"},[_vm._v(_vm._s(event.date_of_event))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(event.place))])]),_c('div',{staticClass:"flex-between"},[_c('div',{staticClass:"events__card--title"},[_vm._v(_vm._s(event.title))]),(_vm.show_delete_icon && _vm.delete_user == event.id)?_c('div',[_c('icon',{staticClass:"is-24x24",attrs:{"name":"x-circle","file":"feather"},nativeOn:{"click":function($event){return _vm.deleteEvent(event.id)}}})],1):_vm._e()])])})],2)}),0):_c('div',{staticClass:"flex-center"},[_vm._v(" No events ")])])}
var staticRenderFns = []

export { render, staticRenderFns }