<template>
  <div class="events pa-4">
    <!-- <div class="flex-between mb-3">
      <h2 class="">Events</h2>
      <add-event @added="getData" />
      <div @click="$router.push({ name: 'Events' })" class="show-all">
        Show All
      </div>
    </div> -->
    <!-- :key="activeFab.icon" -->
    <!-- <pre>{{ grouped_events }}</pre> -->
    <!-- <add-event @added="getData">
      <template v-slot:add>
        <v-fab-transition>
          <v-btn
            color="accent"
            fab
            small
            dark
            bottom
            left
            class="v-btn--example"
          >
            <icon name="plus" file="feather" class="is-24x24"></icon>
          </v-btn>
        </v-fab-transition>
      </template>
    </add-event> -->

    <div class="flex mb-4 align-center">
      <v-btn-toggle v-model="mode" color="accent" group dense mandatory>
        <v-btn>
          <icon
            name="list"
            file="feather"
            :class="['is-24x24', { 'accent-text': mode == 0 }]"
          ></icon>
        </v-btn>
        <v-btn>
          <icon
            name="calendar"
            file="feather"
            :class="['is-24x24', { 'accent-text': mode == 1 }]"
          ></icon>
        </v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <add-event @added="getData">
        <template v-slot:add>
          <!-- <v-fab-transition>
            <v-btn color="accent" dark>
              <icon name="plus" file="feather" class="is-24x24"></icon>
            </v-btn>
          </v-fab-transition> -->
        </template>
      </add-event>
    </div>

    <div v-if="events && events.length && mode == 1">
      <calendar-view :allevents="events" />
    </div>

    <div
      v-else-if="
        grouped_events && Object.keys(grouped_events).length && mode == 0
      "
    >
      <div class="mb-5" v-for="(item, asasd) in grouped_events" :key="item.uid">
        <h3 class="mb-2">{{ asasd }}</h3>
        <div
          v-for="event in item"
          :key="event.id"
          class="events__card"
          v-touch="{
            left: () => {
              show_delete_icon = true;
              delete_user = event.id;
            },
            right: () => {
              show_delete_icon = false;
              delete_user = null;
            },
          }"
        >
          <!-- {{ event }} -->
          <div class="flex-between">
            <div class="body-2">{{ event.date_of_event }}</div>
            <div class="caption">{{ event.place }}</div>
          </div>
          <div class="flex-between">
            <div class="events__card--title">{{ event.title }}</div>
            <div v-if="show_delete_icon && delete_user == event.id">
              <icon
                @click.native="deleteEvent(event.id)"
                name="x-circle"
                file="feather"
                class="is-24x24"
              ></icon>
            </div>
            <!-- <div>{{ event.group }}</div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="events && events.length">
      <div
        v-for="event in events"
        :key="event.id"
        class="events__card"
        v-touch="{
          left: () => {
            show_delete_icon = true;
            delete_user = event.id;
          },
          right: () => {
            show_delete_icon = false;
            delete_user = null;
          },
        }"
      >
        <div class="flex-between">
          <div class="body-2">{{ event.date_of_event }}</div>
          <div class="caption">{{ event.place }}</div>
        </div>
        <div class="flex-between">
          <div class="events__card--title">{{ event.title }}</div>
          <div v-if="show_delete_icon && delete_user == event.id">
            <icon
              @click.native="deleteEvent(event.id)"
              name="x-circle"
              file="feather"
              class="is-24x24"
            ></icon>
          </div>
          <div>{{ event.group }}</div>
        </div>
      </div>
    </div> -->
    <div v-else class="flex-center">
      No events
    </div>
  </div>
</template>

<script>
import AddEvent from "@/components/events/components/add-events.vue";
import CalendarView from "@/components/events/components/calendar-view.vue";
import {
  eventsCollection,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  doc,
  db,
  getStorage,
  ref,
  getDownloadURL,
  limit,
} from "@/firebaseconfig.js";
var _ = require("lodash");
export default {
  components: {
    "add-event": AddEvent,
    "calendar-view": CalendarView,
  },
  data() {
    return {
      events: [],
      grouped_events: {},
      show_delete_icon: false,
      delete_user: null,
      mode: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.is_loading = true;
      const q = query(eventsCollection, orderBy("eventDate", "asc"));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs && querySnapshot.docs.length) {
        this.events = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.grouped_events = _.groupBy(this.events, function(t) {
          return t.group;
        });
        this.is_loading = false;
      } else {
        this.is_loading = false;
        console.log("No data");
      }
    },
    // Accepts the array and key
    groupBy(array, key) {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }); // empty object is the initial value for result object
    },
    async deleteEvent(id) {
      // console.log("id", id);
      this.is_loading = true;
      await deleteDoc(doc(db, "events", id))
        .then(() => {
          console.log("Deleted Successfully");
          this.delete_user = null;
          this.getData();
          this.is_loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.events {
  &__card {
    background-color: #fff;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    &--title {
      margin-top: 0.25rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .v-btn--example {
    bottom: 4rem;
    right: 1rem;
    position: absolute;
  }
}
</style>
